import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form__container form__container--p2p p2p-pay-form__container" }
const _hoisted_2 = { class: "p2p-pay-form__requisites" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "p2p-pay-form__number" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 0,
  class: "p2p-pay-form__copied"
}
const _hoisted_9 = { class: "p2p-pay-form__info p2p-pay-form__info--error" }
const _hoisted_10 = { class: "p2p-pay-form__info p2p-pay-form__info--warning" }
const _hoisted_11 = { class: "p2p-pay-form__info p2p-pay-form__info--warning" }
const _hoisted_12 = { class: "p2p-pay-form__countdown" }
const _hoisted_13 = { class: "p2p-pay-form__buttons" }
const _hoisted_14 = ["disabled", "href"]

import { computed, ref } from "vue";
import { TApproveForm } from "@/types/payment";
import VAlert from "@/components/icons/VAlert.vue";
import VButton from "@/components/VButton.vue";
import VDivider from "@/components/VDivider.vue";
import VCountdown from "@/components/VCountdown.vue";
import { useApi } from "@/plugins/api";
import { usePayment } from "@/use/usePayment";


export default /*@__PURE__*/_defineComponent({
  __name: 'H2HP2PPayForm',
  props: {
  hasAppeals: {
    type: Boolean,
    default: false,
  },
},
  emits: ["openAppeal", "openAppealStatus"],
  setup(__props) {





const { paymentResponse, updatePayment } = usePayment();

const payMethodsMap = {
  card: {
    title: "Номер карты",
    text: "карты",
    data: paymentResponse.value?.info?.p2p_card_data?.target_card_number,
    countdown: paymentResponse.value?.info?.p2p_card_data?.valid_till,
    img: paymentResponse.value?.info?.payment?.bank_group?.logo2,
    bankTitle: paymentResponse.value?.info?.payment?.bank_group?.title,
    holder: paymentResponse.value?.info?.p2p_card_data?.fio,
  },
  phone: {
    title: "Номер телефона",
    text: "телефона",
    data:
      paymentResponse.value?.info?.p2p_wallet_data?.means_type === "phone" &&
      paymentResponse.value?.info?.p2p_wallet_data?.number,
    countdown: paymentResponse.value?.info?.p2p_wallet_data?.valid_till,
    img: paymentResponse.value?.info?.payment?.bank_group?.logo2,
    bankTitle: paymentResponse.value?.info?.payment?.bank_group?.title,
    holder: paymentResponse.value?.info?.p2p_wallet_data?.fio,
  },
  account: {
    title: "Номер расчетного счета",
    text: "расчетного счета",
    data:
      paymentResponse.value?.info?.p2p_wallet_data?.means_type === "account" &&
      paymentResponse.value?.info?.p2p_wallet_data?.number,
    countdown: paymentResponse.value?.info?.p2p_wallet_data?.valid_till,
    img: paymentResponse.value?.info?.payment?.bank_group?.logo2,
    bankTitle: paymentResponse.value?.info?.payment?.bank_group?.title,
  },
};

const paymentMethod = computed(() => {
  return Object.values(payMethodsMap).find((method) => !!method.data);
});

const url = computed(() => process.env.VUE_APP_API_URL);

const form = ref<TApproveForm>({
  tx: paymentResponse.value?.info.tx as string,
  approved_status:
    paymentResponse.value?.info?.payment?.approved_status ?? "process",
});
const loading = ref(false);

const isCopied = ref(false);
const copyToClipboard = async () => {
  await navigator.clipboard.writeText(paymentMethod.value.data ?? "");
  isCopied.value = true;
  setTimeout(() => (isCopied.value = false), 2000);
};

const { payment } = useApi();
const changePaymentStatus = async (status: "confirm" | "cancel") => {
  try {
    loading.value = true;
    form.value.approved_status = status;
    const { data } = await payment.approveP2P(form.value);
    await updatePayment(data.info.tx);
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    class: "form p2p-pay-form",
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (paymentMethod.value.img)
          ? (_openBlock(), _createElementBlock("picture", _hoisted_3, [
              _createElementVNode("img", {
                alt: _unref(paymentResponse)?.info?.payment?.bank_group?.title,
                src: `${url.value}${paymentMethod.value.img}`
              }, null, 8, _hoisted_4)
            ]))
          : (paymentMethod.value.bankTitle)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(paymentMethod.value.bankTitle), 1))
            : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(paymentMethod.value.title), 1),
        _createElementVNode("p", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(paymentMethod.value.data), 1),
          _createElementVNode("button", {
            onClick: _withModifiers(copyToClipboard, ["prevent"])
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("svg", null, [
              _createElementVNode("use", { "xlink:href": "#copy" })
            ], -1)
          ]))
        ]),
        (paymentMethod.value.holder)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(paymentMethod.value.holder), 1))
          : _createCommentVNode("", true)
      ]),
      (isCopied.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Скопировано!"))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(VAlert),
        _cache[6] || (_cache[6] = _createElementVNode("p", null, [
          _createTextVNode(" Переведите на указанный номер "),
          _createElementVNode("span", null, "в точности указанную сумму"),
          _createTextVNode(", одним переводом ")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(VAlert),
        _cache[7] || (_cache[7] = _createElementVNode("p", null, "Дождитесь обработки операции", -1))
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(VAlert),
        _createElementVNode("p", null, " Обратите внимание, номер " + _toDisplayString(paymentMethod.value.text) + " меняется с каждой заявкой ", 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[8] || (_cache[8] = _createTextVNode(" Осталось времени -  ")),
        _createVNode(VCountdown, {
          "date-to": paymentMethod.value.countdown,
          class: "p2p-pay-form__timer"
        }, null, 8, ["date-to"])
      ]),
      _createVNode(VDivider, { class: "divider-section" }),
      _createElementVNode("div", _hoisted_13, [
        _cache[10] || (_cache[10] = _createElementVNode("p", null, "Если вы отправили средства, нажмите кнопку “Я оплатил”", -1)),
        _createVNode(VButton, {
          loading: loading.value,
          class: "form__submit p2p-pay-form__submit",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (changePaymentStatus('confirm')))
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode(" Я оплатил ")
          ])),
          _: 1
        }, 8, ["loading"]),
        _createElementVNode("button", {
          disabled: loading.value,
          href: _unref(paymentResponse).info.payment.fail_url,
          class: "p2p-pay-form__button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (changePaymentStatus('cancel')))
        }, " Отмена ", 8, _hoisted_14),
        (!__props.hasAppeals)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "p2p-pay-form__button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('openAppeal')))
            }, " Обратиться в поддержку "))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "p2p-pay-form__button",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('openAppealStatus')))
            }, " Посмотреть статус апелляции "))
      ])
    ])
  ], 32))
}
}

})