import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "placeholder"]

import { computed, PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputHolder',
  props: {
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
  placeholder: {
    type: String as PropType<string>,
    default: "Имя на карте",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const value = computed<string>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const validateLatin = (e: KeyboardEvent | ClipboardEvent, value: string) => {
  if (/^[A-Za-z\s]+$/.test(value)) return true;
  else e.preventDefault();
};

const handleOnKeyPress = (e: KeyboardEvent) =>
  validateLatin(e, String.fromCharCode(e.keyCode));

const handleOnPaste = (e: ClipboardEvent) =>
  validateLatin(e, (e.clipboardData as DataTransfer).getData("text"));

const handleOnChange = (e: InputEvent) => {
  value.value = (e.target as HTMLInputElement).value.replace(
    /[^A-Za-z\s]/g,
    ""
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("input", {
    class: "input",
    value: value.value,
    placeholder: __props.placeholder,
    onKeypress: handleOnKeyPress,
    onPaste: handleOnPaste,
    onInput: handleOnChange
  }, null, 40, _hoisted_1))
}
}

})